import Vue from 'vue'

import App from './App.vue'

import BootstapVue from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '/assets/css/bootstrap4-flatly-theme.min.css'

import router from '@/router/index'

Vue.config.productionTip = false

Vue.use(BootstapVue)

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
