<template lang="pug">

  div
    b-card-group(deck)
      b-card(class="my-5 w-auto h-auto shadow p-3 mb-5 bg-body rounded" :bg-variant="q.variant ? q.variant : 'info'" no-body v-for="(q, i) in quotes" :key="i")
        b-card-body
          b-row
            b-col(sm="12" md="12" lg="12")
              center #[b-avatar(class="mb-1" :src="`static/${q.image}`" size="4.17em" :alt="q.name")]

            b-col(sm="12" md="12" lg="12")
              blockquote(class="blockquote")
                p(class="mb-0" v-html="q.quote")
                footer(class="blockquote-footer text-primary") {{q.name}} #[cite {{q.title}} ]
                small {{q.year}}

</template>

<script>
  export default {

    data() {
      return {
        quotes: [
          {variant: 'success', name: 'Dato` Mohd Haji Rehat', title: 'COO, D`Harmoni Telco Infra Sdn. Bhd., Malaysia', year: 2018, quote: 'DHTI has grown <b>our revenue by 30%</b> in the last 3 years because Xolas TMS allows us to do more business with the same staff for lower operating costs. Xolas TMS is an integral part of our business.', image: 'img/quotes/rehat.jpg'},
          {variant: 'secondary', name: 'Nusa Rangkuti', title: 'Executive Chairman, World Halal Industry and Trade Alliance, Indonesia', year: 2020, quote: 'I can see Xolas has a huge potential to grow. It goes beyond software management, providing efficiency and solutions for various industries. Great user interface and user experience, simple and easy!', image: 'img/quotes/nusa.jpg'},
        ],
      }
    }

  }
</script>
