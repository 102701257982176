<template>
  <div>
    <nav-bar />
        <router-view></router-view>
    <page-footer />
  </div>
</template>

<script>

import NavBar from '@/layouts/NavBar'
import PageFooter from '@/layouts/PageFooter'

export default {
  name: 'App',

  components: {
    NavBar,
    PageFooter
  }
}
</script>

<style>

html { font-size: 62.5%; }
table { font-size: 100% }

body {
    font-family:Raleway,Roboto,Arial,Helvetica,sans-serif;
    font-size: 1em;
}
@media (max-width: 300px) {
    html { font-size: 70%; }
}

@media (min-width: 500px) {
    html { font-size: 80%; }
}

@media (min-width: 700px) {
    html { font-size: 90%; }
}

@media (min-width: 1200px) {
    html { font-size: 100%; }
}

</style>
