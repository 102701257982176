<template lang="pug">

    vue-flux(:images="images" :transitions="trans" :captions="captions" :options="options")
      template(v-slot:caption)
        flux-caption

      template(v-slot:preloader)
        flux-preloader

      template(v-slot:pagination)
        flux-pagination

      template(v-slot:controls)
        flux-controls

      template(v-slot:index)
        flux-index

</template>

<script>

import {
   VueFlux,
   FluxCaption,
   FluxControls,
   FluxIndex,
   FluxPagination,
   FluxPreloader
} from 'vue-flux'


  export default {

  components: {
    VueFlux,
    FluxCaption,
    FluxControls,
    FluxIndex,
    FluxPagination,
    FluxPreloader
  },

    data() {
      return {

        options: {
          autoplay: true
        },

        trans: ['wave', 'book', 'warp', 'blinds3d', 'round1', 'explode', 'cube'],

        images: [
          'static/img/slides/intro.jpg',
          'static/img/slides/features.jpg',
          'static/img/slides/boxed-set-of-telcos.jpg',
          'static/img/slides/sharer-map.jpg',
          'static/img/slides/requests-detail.jpg',
          'static/img/slides/sites.jpg',
          'static/img/slides/site-info.jpg',
          'static/img/slides/equipment.jpg',
          'static/img/slides/tenancy-expiry.jpg',
          'static/img/slides/rta.jpg',
        ],

        captions: [
          'Redefining Tower Co Operations: Next-Gen Tower/Telco System for 5G and Beyond',
          'Overview of Features Xolas TMS',
          'Some of the Telcos Using Xolas TMS Daily.',
          'Map View of Sharers, can select single sharer',
          'Request to Share, Update and more',
          'Sites: Feature rich sites table',
          'Site Info: Quick Stop to jump to equipment, backhaul, etc.',
          'Equipment: QR Codes for Audit and more',
          'Tenancy Expiry: Two clicks to renew tenancies!',
          'Request to Acquire: Multiple Options for Candidate Sites, with nearest building, power, etc.',
        ],
      }
    }

  }
</script>
