<template lang="pug">

  div(class="tms-container")

    div(class="container")

      b-row(align="center")

        b-col(sm="12" md="12" lg="12")
          h2(class="text-info mt-2") Demo Access
          b-table(hover responsive variant="primary" :items="items")
          p Viewer User can't edit or change anything. If you want a full featured demo, #[router-link(class="text-white" to="/contact") contact us]
          b-button(class="mb-5" variant="warning" href="https://demo.xolas.io" target="_blank") Demo

          b-card(class="my-5"  bg-variant="info" footer="10 Sept 2021")
            b-card-body(title="First Video Conference with TowerXchange")
              p On 10 Sept 2021, Anthony Davie, Founder of Xolas TMS was interviewed by Jack Haddon, Head of Research (Asia) of #[a(class="text-white" target="_blank" href="https://www.towerxchange.com/") Tower Exchange] the tower companies leading global portal
              vue-vimeo-player(video-id="231330602" player-height="528" :options="{responsive: true}")

</template>

<script>

import {vueVimeoPlayer} from 'vue-vimeo-player'

export default {

  components: {
    vueVimeoPlayer
  },

  data () {
    return {
      items: [
        {userName: 'viewer', password: 'viewer@demo'}
      ]
    }
  }
}
</script>
