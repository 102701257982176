<template>

  <section class="pricing-section">

    <div class="container">

      <h2 class="py-4 text-center text-primary">Pricing</h2>

      <ul>
        <li>Pricing plans start by number of sites &amp; cap out at &gt; 1,000 sites beyond that fixed fee.</li>
        <li>Plus you only get billed once a year so any sites you add during the billing period, will only be billed next period. That's very fair, would you agree?</li>
      </ul>

      <hr />

      <div class="row">

        <div class="col-lg-4 col-md-4 col-sm-12">
          <h5>All Plans Come With:</h5>
          <b-table hover :items="standardFeatures"></b-table>
        </div>

        <div class="col-lg-8 col-md-8 col-sm-12">

          <flipper :flipped="flipped">

            <section slot="front" class="pricingTable-header">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <label for="range-2">How Many Sites do You Have?</label>
                    <b-form-input id="range-2" v-model="sites" type="range" size="lg" min="1" max="1001" step="100"></b-form-input>
                    <center class="mt-2 mb-2">Sites &gt; {{ sites }}</center>
                    <center><b-button pill block size="lg" variant="primary" @click="onFlip">See Rates</b-button></center>
                </div>
            </section>

            <section slot="back">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pricingTable" @click="onFlip">
                  <div class="pricingTable-header">
                    <h3 class="heading">{{pricing[rateNo].title}}</h3>
                    <span class="price-value">
                      <span class="currency">$</span>{{formatNumber(pricing[rateNo].fee, 0)}}
                      <span class="fee">p.a.</span>
                    </span>
                  </div>
                  <div class="pricing-content">
                    <ul>
                      <li><b>{{pricing[rateNo].lower}}</b><span v-if="pricing[rateNo].upper">&nbsp;&lt; <b>{{pricing[rateNo].upper}}</b></span><span v-else>+</span> Sites</li>
                      <li>5 &amp; 10 years plans available for significant savings for you</li>
                      <li>Upgrades to any version free of charge</li>
                      <li>If during the year you add more sites, you only pay for additional sites at contract renewal</li>
                      <li><b>Commissioning</b>: Approximately 2 weeks <i>(very simple spreadsheet to fill in, that's it)</i></li>
                    </ul>
      <!--              <a href="#" class="read">sign up</a>  -->
                  </div>
                </div>
              </div>

            </section>

          </flipper>
        </div>


      </div>

    </div>
  </section>

</template>

<script>

import 'vue-flipper/dist/vue-flipper.css'

import Flipper from 'vue-flipper'
import NumberMixins from '@/mixins/NumberMixins'

export default {

  mixins: [NumberMixins],

  components: {
    Flipper
  },

  methods: {
    onFlip () {

      if (this.sites < 100) {
        this.rateNo = 0
      }

      if (this.sites >= 101 && this.sites <= 300) {
        this.rateNo = 1
      }

      if (this.sites >= 301 && this.sites <= 650) {
        this.rateNo = 2
      }

      if (this.sites >= 651 && this.sites <= 999) {
        this.rateNo = 3
      }

      if (this.sites >= 1000) {
        this.rateNo = 4
      }

      this.flipped = !this.flipped
    }
  },

  data () {
    return {
      flipped: false,
      sites: 50,
      rateNo: 0,
      standardFeatures: [
        {feature: 'SaaS Hosting'},
        {feature: 'Unlimited users'},
        {feature: 'Unlimited Storage'},
        {feature: 'Full maintenance coverage'},
        {feature: '24 hour tech support (online)'},
        {feature: 'Full security monitoring'},
        {feature: 'Automatic Backups'},
        {feature: '99.7% uptime guarantee'}
      ],

      pricing: [
        {title: 'Archer', lower: 1, upper: 100, fee: 31512},
        {title: 'Apache', lower: 101, upper: 300, fee: 98646},
        {title: 'Apex', lower: 301, upper: 650, fee: 222534},
        {title: 'Voyager', lower: 651, upper: 999, fee: 231000},
        {title: 'Enterprise', lower: 1000, fee: 285000}
      ]
    }
  }
}
</script>

<style scoped>
	body {background-color: MediumPurple;}

.pricing-section {
  background: #36a4fb;
  overflow: auto;
  padding: 30px 0;
  background-image: url(/static/img/hero/purple.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.pricingTable {
  text-align: center;
  cursor: pointer;
}

.pricingTable .pricingTable-header {
  padding: 30px 0;
  background: #4d4d4d;
  position: relative;
  transition: all .3s ease 0s
}

.pricingTable:hover .pricingTable-header {
  background: MediumSlateBlue

}


.pricingTable .pricingTable-header:after,.pricingTable .pricingTable-header:before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #d9d9d8;
  position: absolute;
  bottom: 12px
}

.pricingTable .pricingTable-header:before {
  left: 40px
}

.pricingTable .pricingTable-header:after {
  right: 40px
}

.pricingTable .heading {
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 0
}

.pricingTable .price-value {
  display: inline-block;
  position: relative;
  font-size: 39px;
  font-weight: 700;
  color: #09b1c5;
  transition: all .3s ease 0s
}

.pricingTable:hover .price-value {
  color: #fff
}

.pricingTable .currency {
  font-size: 30px;
  font-weight: 700;
  position: absolute;
  top: 6px;
  left: -19px
}

.pricingTable .fee {
  font-size: 16px;
  color: #fff;
  position: absolute;
  bottom: 15px;
  right: -30px;
  text-transform: uppercase
}

.pricingTable .pricing-content {
  padding-top: 50px;
  margin-bottom: 50px;
  background: #fff;
  position: relative
}

.pricingTable .pricing-content:after,.pricingTable .pricing-content:before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #7c7c7c;
  position: absolute;
  top: 12px
}

.pricingTable .pricing-content:before {
  left: 40px
}

.pricingTable .pricing-content:after {
  right: 40px
}

.pricingTable .pricing-content ul {
  padding: 0 20px;
  margin: 0;
  list-style: none
}

.pricingTable .pricing-content ul:after,.pricingTable .pricing-content ul:before {
  content: "";
  width: 8px;
  height: 46px;
  border-radius: 3px;
  background: linear-gradient(to bottom,#818282 50%,#727373 50%);
  position: absolute;
  top: -22px;
  z-index: 1;
  box-shadow: 0 0 5px #707070;
  transition: all .3s ease 0s
}

.pricingTable:hover .pricing-content ul:after,.pricingTable:hover .pricing-content ul:before {
  background: linear-gradient(to bottom,#40c4db 50%,#34bacc 50%)
}

.pricingTable .pricing-content ul:before {
  left: 44px
}

.pricingTable .pricing-content ul:after {
  right: 44px
}

.pricingTable .pricing-content ul li {
  font-size: 15px;
  font-weight: 700;
  color: #777473;
  padding: 10px 0;
  border-bottom: 1px solid #d9d9d8
}

.pricingTable .pricing-content ul li:last-child {
  border-bottom: none
}

.pricingTable .read {
  display: inline-block;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  background: #d9d9d8;
  padding: 8px 25px;
  margin: 30px 0;
  transition: all .3s ease 0s
}

.pricingTable .read:hover {
  text-decoration: none
}

.pricingTable:hover .read {
  background: #09b1c5
}

@media screen and (max-width:990px) {
  .pricingTable {
    margin-bottom: 25px
  }
}
</style>
