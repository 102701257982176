<template lang="pug">
    div(class="my-5 w-auto shadow p-3 mb-5")
      vue-flux(:images="images" :transitions="trans" :captions="captions" :options="options")
        template(v-slot:caption)
          flux-caption

        template(v-slot:preloader)
          flux-preloader

        template(v-slot:pagination)
          flux-pagination

        template(v-slot:controls)
          flux-controls

        template(v-slot:index)
          flux-index

</template>

<script>

import {
   VueFlux,
   FluxCaption,
   FluxControls,
   FluxIndex,
   FluxPagination,
   FluxPreloader
} from 'vue-flux'


  export default {

  components: {
    VueFlux,
    FluxCaption,
    FluxControls,
    FluxIndex,
    FluxPagination,
    FluxPreloader
  },

    data() {
      return {

        options: {
          autoplay: true
        },

        trans: ['wave', 'book', 'warp', 'blinds3d', 'round1', 'explode', 'cube'],

        images: [
          'static/img/views/telco-view-dashboard.jpg',
          'static/img/views/telco-request-to-acquire-1.jpg',
          'static/img/views/telco-request-to-acquire-2.jpg',
          'static/img/views/telco-request-to-acquire-3.jpg',
          'static/img/views/telco-request-to-share.jpg',
          'static/img/views/telco-request-to-upgrade.jpg',
          'static/img/views/telco-requests-summary.jpg',
          'static/img/views/backhaul-providers.jpg',
          'static/img/views/backhaul-customers.jpg',
          'static/img/views/backhaul-requests.jpg',
          'static/img/views/contractor-dashboard-1.jpg',
          'static/img/views/contractor-ticket-summary.jpg',
          'static/img/views/contractor-workflow-1.jpg',
          'static/img/views/contractor-workflow-checklist.jpg',
          'static/img/views/landowner-view.jpg',
        ],

        captions: [
          'Telco View: Dashboard',
          'Telco View: Request to Acquire 1',
          'Telco View: Request to Acquire 1Request to Acquire 2',
          'Request to Acquire: Multiple Options for Candidate Sites, with nearest building, power, etc.',
          'Telco View: Request to Share',
          'Telco View: Request to Upgrade',
          'Telco View: Requests Summary',
          'Backhaul View: Provider Details',
          'Backhaul View: Customers',
          'Backhaul View: Requests',
          'Contractor Dashboard - Tickets',
          'Contractor Dashboard - Ticket Summary',
          'Contractor View: Site Workflow',
          'Contractor View: Site Workflow Checklist',
          'Landowner View: Dashboard',
        ],
      }
    }

  }
</script>
