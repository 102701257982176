import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import HomePage from '@/pages/HomePage'
import About from '@/pages/About'
import CaseStudy from '@/pages/CaseStudy'
import Contact from '@/pages/Contact'
import Demo from '@/pages/Demo'
import Pricing from '@/pages/Pricing'
import Privacy from '@/pages/Privacy'
import News from '@/pages/News'
import Team from '@/pages/Team'
import Views from '@/pages/Views'

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomePage
    },
    {
      path: '/about',
      name: 'About',
      component: About
    },
    {
      path: '/cases',
      name: 'Case Studies',
      component: CaseStudy
    },
    {
      path: '/contact',
      name: 'Contact Us',
      component: Contact
    },
    {
      path: '/demo',
      name: 'Demo',
      component: Demo
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: Privacy
    },
    {
      path: '/news',
      name: 'News',
      component: News
    },
    {
      path: '/team',
      name: 'Team',
      component: Team
    },
    {
      path: '/views',
      name: 'Views',
      component: Views
    },
  ]
})
