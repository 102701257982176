<template lang="pug">

  div(class="cases-container")

    b-jumbotron
      template(#lead)
        h2(class="text-primary") Case Studies
        b-card-group(deck)
          b-card(bg-variant="primary" text-variant="white" header="Total Sites" class="text-center")
            b-card-text Over #[strong {{sites}}] sites under management

          b-card(bg-variant="primary" text-variant="white" header="Years Operating" class="text-center")
            b-card-text Over #[strong {{years}} years] of successful operations with #[strong 99.9%] availability

          b-card(bg-variant="primary" text-variant="white" header="Client Success" class="text-center")
            b-card-text #[strong {{clients}} Tower Co's] {{telcos}}+ telcos using the system daily.


    div(class="container")

      div(class="row")

        div(class="col-lg-12 col-md-12 col-sm-12")
          b-card(class="my-5 w-auto h-auto" bg-variant="warning"
           v-for="(c, i) in cases" :key="i"
           overlay :img-src="`static/${c.image}`" :img-alt="c.name")

            b-card-title(class="d-none d-sm-block") {{c.title}}

            b-card-body
              b-row
                b-col(sm="12" md="12" lg="3")
                  a(:href="c.link" target="_blank") #[img(:src="`static/${c.logo}`" :alt="c.name" height="40")]

                b-col(sm="12" md="12" lg="9")
                  p(class="mx-3") {{ c.text }}


</template>

<script>
  export default {

    data() {
      return {
        clients: 3,
        telcos: 10,
        sites: 1639,
        years: ((new Date().getFullYear())-2016),
        cases: [
          {title: 'D\'Harmoni Telco Infra Sdn Bhd', text: 'Customer since November 2016. When they started using Xolas TMS they only had 600 sites in Johor. They pioneered Requests module in 2018 with all the telcos using it. Processing new site went from 3 months to 1 month to live. Then pioneered QR Codes for Audit in 2020. Dato’ Rehat deserves special mention for his vision and persistence to make Xolas TMS available to Malaysian tower companies.', logo: 'img/cases/dhti.png', image: 'img/cases/johor.jpg', link: 'https://dharmoni.xolas.io'},
          {title: 'Melaka ICT Holdings Sdn Bhd', text: 'Customer since March 2021. First customer sign up for the 5 year plan. Even though Melaka is a smaller state in Malaysia the Board have great expansion plans for which Xolas TMS will allow increase of business without increasing staff. It’s a pleasure to work with such an energetic company and staff.', logo: 'img/cases/micth.png', image: 'img/cases/melaka.jpg', link: 'https://micth.xolas.io'},
          {title: 'Common Tower Technologies Sdn Bhd', text: 'Customer since July 2020. As part of an ambitious expansion plan, the Board met with Xolas representatives Feb 2020 and mandated purchase of Xolas TMS.', logo: 'img/cases/common-tower.png', image: 'img/cases/sabah.jpg', link: 'https://common-tower.xolas.io'},
          {title: 'Tele-Flow Corporation Sdn Bhd', text: 'Customer since Feb 2017 but sold most their sites to EdgePoint Feb 2022 so no longer an active client. Given Tele-Flow’s operations are mostly in north Malaysia, and their HQ in is Cyberjaya, Xolas TMS has been invaluable to them to store legal docs like tenancy and technical proposals on the system since prior to the all the docs where in the branch offices creating an admin nightmare. With a subsidiary company: Bungaraya Sdn Bhd', logo: 'img/cases/tele-flow.png', image: 'img/cases/cyberjaya.jpg', link: '#/cases'},
        ],
      }
    }

  }
</script>

<style scoped>
body {background-color: moccasin;}

.cases-container {
  background: #36a4fb;
  overflow: auto;
  background-image: url(/static/img/hero/yellow.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.jumbotron {
  background-image: url(/static/img/bg/tower3.jpg);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  background-color: goldenrod;
  background-blend-mode: soft-light;
  padding: 2em;
}

</style>
