<template lang="pug">

  b-tabs(content-class="mt-3" pills fill)

    b-tab(title="Overall" active nav-class="bg-primary")
      b-table(hover responsive :items="overallItems")

    b-tab(title="Reports" nav-class="bg-primary")
      b-table(hover responsive :items="dashboardItems")

    b-tab(title="Sites" lazy nav-class="text-info")
      b-table(hover responsive :items="siteItems")

    b-tab(title="Requests" lazy)
      b-table(hover responsive :items="requestsItems")

    b-tab(title="Settings" lazy nav-class="bg-primary")
      b-table(hover responsive :items="settingsItems")

</template>

<script>
  export default {

    data() {
      return {
        overallItems: [
          {area: 'Tower View', details: 'Depending role, access to the platform'},
          {area: 'Telco View', details: 'Sites, Requests, Maps, Tickets'},
          {area: 'Tower Supplier View', details: 'Sites by Tower Supplier, Structure Report, Site Structure and Equipment'},
          {area: 'Contractor View', details: 'Tickets assigned to contractor, site workflows'},
          {area: 'Backhaul View', details: 'Sites by Backhaul Provider, Request to Backhaul, Map'},
          {area: 'Landlord View', details: 'Rental Report and Tenancy Due report.'}
        ],


        dashboardItems: [
          {area: 'Sites', details: 'Roll Out Report'},
          {area: 'Sites', details: 'Ways Report'},
          {area: 'Sites', details: 'Site Groups'},
          {area: 'Sites', details: 'Tower Suppliers'},
          {area: 'Sites', details: 'Backhaul Report'},
          {area: 'Sites', details: 'Structure Report'},
          {area: 'Sites', details: 'Equipment Report'},
          {area: 'Maintenance', details: 'Tickets'},
          {area: 'Maintenance', details: 'Site Workflows'},
          {area: 'Property', details: 'Summary - Average Rental, etc.'},
          {area: 'Property', details: 'Expiry Report'},
          {area: 'Property', details: 'Tenancy Due'},
          {area: 'Property', details: 'Landlords Report'},
          {area: 'Property', details: 'Rental by Month'},
          {area: 'Finance', details: 'Summary- total due by sharers'},
          {area: 'Finance', details: 'New Revenue Report - By Year'},
          {area: 'Finance', details: 'Utility Report'}
        ],

        requestsItems: [
          {area: 'RTA', details: 'Request to Acquire'},
          {area: 'RTS', details: 'Request to Share'},
          {area: 'RTU', details: 'Request to Upgrade'},
          {area: 'RTB', details: 'Request to Backhaul'},
          {area: 'RTD', details: 'Request to Dismantle'},
          {area: 'RTL', details: 'Request to Local Authorities'}
        ],

        siteItems: [
          {area: 'Info', details: 'Site Info'},
          {area: 'Actions', details: 'Actions: Requests, Dismantling'},
          {area: 'Equipment', details: 'Total equipment/assets for that site'},
          {area: 'Tickets', details: 'Maint. Tickets for the site'},
          {area: 'Structure', details: 'One or more Structures per site'},
          {area: 'Attachments', details: 'Images or PDFs'},
          {area: 'Sharers', details: 'Telco Sharers: Finance, Projects, Equipment, Backhaul'},
          {area: 'Permits', details: 'Site Permits'},
          {area: 'Insurance', details: 'Site Insurance Policies (with Attachments)'},
          {area: 'Utility', details: 'Utility Bills per Site'},
          {area: 'Finance', details: 'Site, Sharer Invoices & V.O.s'},
          {area: 'Backhaul', details: 'Backhaul Providers'}
        ],

        settingsItems: [
          {area: 'Organisation', menu: 'Tower Co.', details: 'Tower Co. and Staff'},
          {area: 'Organisation', menu: 'Sharers', details: 'Sharers and Staff'},
          {area: 'Organisation', menu: 'Contactors', details: 'Contractors and Staff (can add sites to specific contractors)'},
          {area: 'Organisation', menu: 'Backhaul Providers', details: 'Backhaul Providers and staff'},
          {area: 'Organisation', menu: 'Tower Suppliers', details: 'Tower Suppliers for Site Construction'},
          {area: 'Organisation', menu: 'Insurance', details: 'Insurance Providers'},
          {area: 'Organisation', menu: 'Utility Providers', details: 'Utility Providers'},
          {area: 'Organisation', menu: 'Authorities', details: 'Local and National Authorities'},
          {area: 'Organisation', menu: 'Landlords', details: 'Individual Landlords'},
          {area: 'Structures', menu: 'Master Structures', details: 'Template for Structures'},
          {area: 'Master Workflow', menu: 'Template for Site Workflow', details: 'Create your own checklists for installation, Dismantling, Audits, etc.'},
          {area: 'Localities', menu: 'Localities', details: 'Country, Regions, States, Districts, Municipalities'},
          {area: 'Finance', menu: 'Settings', details: 'Tax Rates, Tax Number, Interest Rate'},
          {area: 'Finance', menu: 'Agreements', details: 'Sharer Agreements'},
          {area: 'Finance', menu: 'Rates', details: 'Sharer Rates'},
          {area: 'Finance', menu: 'Rates', details: 'Utilities Sharing Rates'},
          {area: 'Finance', menu: 'Rates', details: 'Tenancy Renewal/Periods Rates'},
          {area: 'Backup', menu: 'Sites & Projects', details: 'Sites, Sharers, Assets'},
          {area: 'Backup', menu: 'Requests', details: 'RTA, RTD, RTS, RTU and RTB'},
          {area: 'Backup', menu: 'Maint. & Admin', details: 'Tickets, Master & Site Workflows, Calendar'},
          {area: 'Backup', menu: 'Property', details: 'Tenancies, Landlords, Payments, Calendar'},
          {area: 'Backup', menu: 'Finance', details: 'Statement, Utility, Invoices, Works Orders, Land Payments'},
          {area: 'Audit', menu: 'Logs', details: 'User activity over the entire system.'}
        ]
      }
    }

  }
</script>
