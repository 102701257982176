<template lang="pug">

  div(class="tms-container")

    b-jumbotron
      template(#header)
        p(class="text-white text-center") Next-Gen Tower Management

      template(#lead)
        p(class="text-white text-center") #[strong(class="text-warning") Beyond 5G]: Saves Time, Increases Profit without expanding staff

    div(class="container")

      h4(class="text-info") Xolas Tower Management System (TMS)
      ul
        li Redefining Telco/Tower Co. Operations for Next-Gen Performance and profitability beyond 5G.
        li In daily use by #[strong {{clients}} Tower Co's] for the last {{years}} years with {{sites}} site assets, with half a day unplanned downtime (excluding maintenance activities with 4 days downtime) in {{years}} years.
        li #[strong {{telcos}} Telcos] using the system daily for Requests module.
        li Special Views for: Projects, Maintenance, Property, Finance and Telco/Contactor View
        li Improve your bottom line without increasing staff.

      b-row
        b-col(class="mt-5" sm="12")
          tms-carousel
          br

        b-col(class="mt-5" sm="12")
          tms-quotes

        b-col(s="12")
          features-tables


</template>

<script>

import FeaturesTables from '@/components/FeaturesTables'

import TmsCarousel from '@/components/TmsCarousel'
import TmsQuotes from '@/components/TmsQuotes'

export default {
  components: {
    FeaturesTables,
    TmsCarousel,
    TmsQuotes
  },

  data () {
  return {
      clients: 3,
      telcos: 10,
      sites: 1639,
      years: ((new Date().getFullYear())-2016),
      }
  }
}

</script>

<style>
.tms-container {
  background: #36a4fb;
  overflow: auto;
  background-image: url('/static/img/hero/blue.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.jumbotron {
  background-image: url('/static/img/bg/tower3.jpg');
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  background-color: slategrey;
  background-blend-mode: color-burn;
}
/*
    color
    color-burn
    color-dodge
    darken
    difference
    exclusion
    hard-light
    hue
    lighten
    luminosity
    multiply
    normal
    overlay
    saturation
    screen
    soft-light
 */



.text-white {
  color: white;
}
</style>
