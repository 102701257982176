<template lang="pug">

  div(class="team-container")

    div(class="container")
      h1(class="text-warning") Team
      p We run a lean staffing policy, software companies live or die by on-going costs. Anthony, the founder, having gone through three recessions in his life, came up the strategy of a core team, that manages associates on the a project by project basis.
      p We also came up with the strategy of we would define success by repeat business not volume of business.

      b-row

        b-col
          b-card(class="my-5 team-card" bg-variant="warning" v-for="(t, i) in team" :key="i" :title="t.name" :sub-title="t.title" sub-title-text-variant="primary")

            b-card-body
              b-row
                b-col(sm="4" md="4" lg="2")
                  b-card-img(class="img-fluid shadow-sm p-2 mb-3 bg-body rounded" :src="`static/${t.image}`" :alt="t.name")

                b-col(sm="8" md="8" lg="10")
                  p(v-html="t.bio")
                  b-button(:href="t.link" variant="primary" target="_blank") LinkedIn Profile

          h4 Original Development Team
          b-row
            b-col(sm="12" md="6" lg="6" v-for="(t, i) in devTeam" :key="i" )
              b-card(class="my-5 team-card" bg-variant="warning" :title="t.name" :sub-title="t.title" sub-title-text-variant="primary" :img-src="`static/${t.image}`" :alt="t.name" img-left)




</template>

<script>

export default {

  data () {
    return {
      team: [
        {name: 'Anthony Davie', title: 'Founder, Managing Director', image: 'img/team/Anthony.jpg', link: 'https://www.linkedin.com/in/anthonydavie/', bio: 'Entrepreneur, Author, Trainer, Technologist, Strategist. Anthony was born in Mexico, grew up in Latin America and the UK. He’s lived in Asia for 25 years and has a background of running startups, being on global projects in Silicon Valley and the Middle East, along with roles in personal development training, CTO and CIO roles for public and private companies. He’s deeply passionate about our stewardship of the planet and how blockchain and other disruptive technologies can create a more egalitarian, fair and sustainable future. He’s written a book called the <b><a class="text-primary" href="https://www.thepowerofsimplicity.com" target="_blank">“The Power of Simplicity”</a></b>.'},
        {name: 'Syahrul Azrai Mat Hasan', title: 'Associate Director of Operations',  image: 'img/team/Azrai.jpg', link: 'https://www.linkedin.com/in/ajaeontherun/', bio: 'Big Picture Thinker and also Hands On Pragmatist his career stretches from IBM, Hewlett-Packard, Shell, Standard Chartered Bank, T-Systems and MDEC (Malaysia Digital Economy Corporation). His passion is mentoring young entrepreneurs and of course, his family of five and coffee, plus assorted cats.'},
      ],
      devTeam: [
        {name: 'Soo Miew Ling', title: 'Project Manager', image: 'img/team/miewling.png'},
        {name: 'Mohammed Jalala', title: 'Backend Developer', image: 'img/team/jalala.png'},
        {name: 'Eazrik Zaulie', title: 'UX Developer', image: 'img/team/eazrik.png'},
        {name: 'Bathulah Mahir', title: 'Support', image: 'img/team/bathulah.png'},
      ]
    }
  }
}
</script>

<style scoped>
body {background-color: moccasin;}

.team-container {
  background: #36a4fb;
  overflow: auto;
  padding: 30px 0;
  background-image: url(/static/img/hero/yellow.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.team-card {
  background: #36a4fb;
  overflow: auto;
  padding: 30px 0;
  background-image: url(/static/img/team/bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-blend-mode: saturation;

}

</style>
