/*
Number formatting mixins.
------------------------------------------------------------------------ */
import accounting from 'accounting'

export default {
  methods: {

    totalDue (amount, tax, taxable = false) {
      if (taxable) {
        return Number(amount) + Number(tax)
      } else {
        return Number(amount)
      }
    },

    randBetween (a = 1, b = 100) {
      return Math.floor(Math.random() * b) + a
    },

    toNumber (value) {
      return accounting.unformat(value)
    },

    formatInteger (value) {
      return accounting.formatNumber(value, 0)
    },

    formatPercent (value) {
      if (!value || !this.isNumber(value)) {
        return '-'
      } else {
        if (value <= 1.0) {
          value = value * 100.0
        }
      }

      return accounting.formatNumber(value, 0) + '%'
    },

    formatMoney (value, decimal = 2, currency = '$') {
      return accounting.formatMoney(value, currency, decimal)
    },

    formatNumber (value, decimal = 2) {
      return accounting.formatNumber(value, decimal)
    },

    roundToNearest (value, roundup = 1000) {
      var num = Number(value)

      if (typeof num === 'number') {
        return Math.round(num / roundup) * roundup
      } else {
        return NaN
      }
    },

    abreviateNumber (number, decPlaces) {
      // 2 decimal places => 100, 3 => 1000, etc
      decPlaces = Math.pow(10, decPlaces)

      // Enumerate number abbreviations
      var abbrev = ['k', 'm', 'b', 't']

      // Go through the array backwards, so we do the largest first
      for (var i = abbrev.length - 1; i >= 0; i--) {
        // Convert array index to "1000", "1000000", etc
        var size = Math.pow(10, (i + 1) * 3)

        // If the number is bigger or equal do the abbreviation
        if (size <= number) {
          // Here, we multiply by decPlaces, round, and then divide by decPlaces.
          // This gives us nice rounding to a particular decimal place.
          number = Math.round(number * decPlaces / size) / decPlaces

          // Handle special case where we round up to the next abbreviation
          if ((number === 1000) && (i < abbrev.length - 1)) {
            number = 1
            i++
          }

          // Add the letter for the abbreviation
          number = accounting.formatInteger(number)
          number += abbrev[i]

          // We are done... stop
          break
        }
      }

      return number
    },

    formatThousands (value) {
      var div = 10000
      var num = this.roundToNearest(value, div) / (div / 100)
      return accounting.formatNumber(num, 0) + 'k'
    },

    formatTenThousands (value) {
      var div = 100000
      var num = this.roundToNearest(value, div) / (div / 100)
      return accounting.formatNumber(num, 0) + 'K'
    },

    formatMillions (value) {
      var div = 1000000
      var num = this.roundToNearest(value, div) / (div / 100)
      return accounting.formatNumber(num, 0) + 'M'
    },

    formatBillions (value) {
      var div = 10000000
      var num = this.roundToNearest(value, div) / (div / 100)
      return accounting.formatNumber(num, 0) + 'M'
    },

    formatBoolean (value) {
      if (value) {
        if (value === 1 || value === true || value.toUpperCase() === 'YES' || value.toUpperCase() === 'TRUE') {
          return 'Yes'
        } else {
          return 'No'
        }
      } else {
        return null
      }
    },

    addSuffix (i, html = true, bold = true, text = null) {

      if (!i) {
        return null
      }

      var j = i % 10,
        k = i % 100

      var suf = 'th'

      if (j === 1 && k !== 11) {
        suf = 'st'
      }
      if (j === 2 && k !== 12) {
        suf = 'nd'
      }
      if (j === 3 && k !== 13) {
        suf = 'rd'
      }
      if (html) {
        return (bold ? '<b>' + i + '</b>' : i) + '<sup>' + suf + '</sup>' + (text ? ' ' + text : '')

      } else {
        return i + suf + (text ? ' ' + text : '')
      }

    },

    toWords (s, upperCase = false) {
      // Convert numbers to words
      // copyright 25th July 2006, by Stephen Chapman http://javascript.about.com
      // permission to use this Javascript on your web page is granted
      // provided that all of the code (including this copyright notice) is
      // used exactly as shown (you can change the numbering system if you wish)

      // American Numbering System
      var th = ['', 'thousand', 'million', 'billion', 'trillion']
      // uncomment this line for English Number System
      // var th = ['', 'thousand', 'million', 'milliard', 'billion']

      var dg = ['zero', 'one', 'two', 'three', 'four',
        'five', 'six', 'seven', 'eight', 'nine']

      var tn = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen',
        'seventeen', 'eighteen', 'nineteen']

      var tw = ['twenty', 'thirty', 'forty', 'fifty',
        'sixty', 'seventy', 'eighty', 'ninety']

      s = s.toString()
      s = s.replace(/[, ]/g, '')

//      if (s !== parseFloat(s)) {
//        return 'not a number'
//      }

      var x = s.indexOf('.')

      if (x === -1) {
        x = s.length
      }

      if (x > 15) {
        return 'too big'
      }

      var n = s.split('')

      var str = ''
      var sk = 0

      for (var i = 0; i < x; i++) {
        if ((x - i) % 3 === 2) {

          if (n[i] === '1') {
            str += tn[Number(n[i + 1])] + ' '
            i++
            sk = 1
          } else if (n[i] !== 0) {
            str += tw[n[i] - 2] + ' '
            sk = 1
          }
        } else if (n[i] !== 0) {
          str += dg[n[i]] + ' '

          if ((x - i) % 3 === 0) {
            str += 'hundred '
            sk = 1
          }
        }

        if ((x - i) % 3 === 1) {
          if (sk) {
            str += th[(x - i - 1) / 3] + ' '
            sk = 0
          }
        }
      }  // for

      if (x !== s.length) {
        str += 'point '

        //      for (var i=x+1; str.replace(/\s+/g, ' '); )
      }

      return upperCase ? str.toUpperCase() : str
    },

    degreesToRadians(degrees) {
      return degrees * Math.PI / 180
    },

    distanceInKmBetween(lat1, lon1, lat2, lon2) {
      var earthRadiusKm = 6371

      var dLat = this.degreesToRadians(lat2 - lat1)
      var dLon = this.degreesToRadians(lon2 - lon1)

      lat1 = this.degreesToRadians(lat1)
      lat2 = this.degreesToRadians(lat2)

      var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      return earthRadiusKm * c
      }


  }

}
