<template lang="pug">

  div(class="news-container")
    div(class="container")

      h1(class="text-white") News

      b-card(no-body class="overflow-hidden" bg-variant="info" footer="1st Dec 2021")
        b-row(no-gutters)
          b-col(md="2")
            b-card-img(src="static/img/news/te-logo-only.png" alt="Tower Exchange Logo" class="rounded-0")
          b-col(md="10")
            b-card-body(title="TowerXchange Interview with Jack Haddon")
              b-card-text
                p(class="text-primary") #[a(class="text-white" target="_blank" href="https://www.towerxchange.com/article/2blg3ddo08rqs34lk464g/how-xolas-tms-is-turning-malaysian-telecom-companies-into-lean-mean-tower-managing-machines") How Xolas TMS is turning Malaysian telecom companies into lean, mean, tower managing machines.] Interview with Jack Haddon, Head of Research, Asia with Founder Anthony Davie.
      hr

      b-card(class="my-5"  bg-variant="info" footer="10 Sept 2021")
        b-card-body(title="First Video Conference with TowerXchange")
          p On 10 Sept 2021, Anthony Davie, Founder of Xolas TMS was interviewed by Jack Haddon, Head of Research (Asia) of #[a(class="text-white" target="_blank" href="https://www.towerxchange.com/") Tower Exchange] the tower companies leading global portal
          vue-vimeo-player(video-id="231330602" player-height="528" :options="{responsive: true}")

      hr

      b-card(no-body class="overflow-hidden" bg-variant="info" footer="24th Dec 2022")
        b-row(no-gutters)
          b-col(md="2")
            b-card-img(src="static/icon-trans.png" alt="Xolas TMS" class="rounded-0")
          b-col(md="10")
            b-card-body(title="Breaking News! 3 clients have signed 5 year contracts")
              b-card-text
                p(class="text-primary") D'Harmoni Telco Infra Sdn Bhd (Johor), Melaka ICT Holdings Sdn Bhd (Melaka),  and Common Tower Technology Sdn Bhd (Sabah) have both signed 5 year contracts.

      hr

      b-card(no-body class="overflow-hidden" bg-variant="info" footer="15 Aug 2022")
        b-row(no-gutters)
          b-col(md="2")
            b-card-img(src="static/icon-trans.png" alt="Xolas TMS" class="rounded-0")
          b-col(md="10")
            b-card-body
              b-card-text
                p(class="text-primary")
                  <b><router-link class="text-white" to="/views">Version 4.0</router-link></b>  released featuring:
                  ul
                    li Tower Co Views
                    li Telco Views
                    li Backhaul Views
                    li Landlord Views
                    li Tower Supplier Views
                    li Contractor Views

      hr

      b-card(class="my-5" bg-variant="info" footer="6-10 Dec 2021" img-src="static/img/news/te-asia-meetup.jpg" overlay)
        b-card-body(class="text-white" title="TowerXchange Asia Meetup 2021")
        p(class="text-secondary") Xolas TMS will be featured at #[a(class="text-white" target="_blank" href="https://meetup.towerxchange.com/event/14b1d658-ebaa-4189-9c8d-c6c51d67de85/summary") Tower Exchange Meetup Asia 2021] on the round table for Malaysia.
        ul
          li #[a(class="text-white" target="_blank" href="https://meetup.towerxchange.com/event/14b1d658-ebaa-4189-9c8d-c6c51d67de85/websitePage:0cfd9d0b-18c7-4d01-ab2e-8f21a1405fba") Agenda]
          li #[a(class="text-white" target="_blank" href="https://meetup.towerxchange.com/event/14b1d658-ebaa-4189-9c8d-c6c51d67de85/websitePage:579969a5-62bc-4734-970e-fb077aaadfe8") Speakers]
          li #[a(class="text-white" target="_blank" href="https://meetup.towerxchange.com/event/14b1d658-ebaa-4189-9c8d-c6c51d67de85/websitePage:aa57810e-e2f6-4f66-b1ec-2a99fee8cd67") Sponsors]

</template>

<script>

 import {vueVimeoPlayer} from 'vue-vimeo-player'

export default {

  components: {
    vueVimeoPlayer
  }

}

</script>

<style scoped>

.news-container {
  background: #36a4fb;
  overflow: auto;
  padding: 30px 0;
  background-image: url(/static/img/hero/blue.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
